<template>
	<button type="button">π</button>
</template>

<style lang="scss" scoped>

button
{
	position: fixed;
	bottom: 8px;
	right: 8px;
	width: 24px;
	height: 24px;
	line-height: 24px;
	margin: 0;
	padding: 0;
	background-color: rgba( 0, 0, 0, 0.50 );
	border-radius: 12px;
	border: 1px solid #FFFFFF;
	color: #FFFFFF;
	text-align: center;
}

</style>
