
export
function p_false ()
{
	return false;
}

export
function p_true ()
{
	return true;
}

//==================================================================================================

export
function p_equal ( v1, v2 )
{
	return v1 === v2;
}

export
function p_greater_than ( v1, v2 )
{
	return v1 > v2;
}

export
function p_less_than ( v1, v2 )
{
	return v1 < v2;
}

//==================================================================================================

export
function p_and ( ...predicates )
{
	return function ( ...args )
	{
		return !predicates.find( function ( predicate ) { return !predicate( ...args ); } );
	};
}

export
function p_or ( ...predicates )
{
	return function ( ...args )
	{
		return !!predicates.find( function ( predicate ) { return predicate( ...args ); } );
	};
}

export
function p_not ( predicate )
{
	return function ( ...args ) { return !predicate( ...args ); };
}
