<template>
	<router-view />
</template>

<script>

export default
{
	mounted ()
	{
		const query = new URLSearchParams( location.search );

		if ( query.has( 'theme' ) )
		{
			const theme = query.get( 'theme' );
			swapThemeClass( theme.replace( /[^_\-0-9A-Z]+/gi, '' ) );
		}
	}
}

function swapThemeClass ( theme )
{
	const list = document.documentElement.classList;

	list.forEach( function ( token )
	{
		if ( token.startsWith( 'tm-' ) ) list.remove( token );
	} );

	list.add( `tm-${theme}` );
}

</script>

<style lang="scss">

@import '~@/css/core/view/base';

#app
{
	width: 100%;
	max-width: 8.50in;
	margin: 0 auto;
	background-color: transparent;
	border-radius: 6px;
	color: #FFFFFF;
	font-family: Helvetica, Arial, sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

</style>
