
import JSON5 from 'json5';

//==================================================================================================

export
function jsonSilentParse ( content )
{
	let ex;
	let rv;

	try { rv = content != null ? JSON5.parse( String( content ) ) : undefined; }
	catch( er ) { ex = er; }

	return [ ex, rv ];
}
