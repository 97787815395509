
import { isVoid } from 'nn-validate';
import { isFunction } from 'nn-validate';

import { p_equal } from '@/tools/predicate';

//==================================================================================================

function sanitize_predicate ( predicate, default_predicate )
{
	let rv;

	if ( isFunction( predicate ) )
	{
		rv = predicate;
	} else if ( isVoid( predicate ) ) {
		rv = default_predicate || p_equal;
	} else {
		throw new TypeError( `The predicate "${predicate}" is not a function.` );
	}

	return rv;
}

//==================================================================================================

export
function array_until ( container, fn )
{
	let rv;

	let ii = 0;
	const ic = container.length;
	for ( ; ii < ic; ii++ )
	{
		rv = fn( container[ ii ], ii, container );
		if ( rv != null ) break;
	}

	return rv;
}

export
function array_while ( container, fn )
{
	const rv = [];
	let tmp;

	let ii = 0;
	const ic = container.length;
	for ( ; ii < ic; ii++ )
	{
		tmp = fn( container[ ii ], ii, container );
		if ( tmp == null ) break;
		rv.push( tmp );
	}

	return rv;
}

export
function swap_indexes ( container, index1, index2 )
{
	const value = container[ index1 ];
	container[ index1 ] = container[ index2 ];
	container[ index2 ] = value;
}

export
function intersection ( a1, a2, predicate )
{
	predicate = sanitize_predicate( predicate );

	return a1.filter( function ( v1 )
	{
		return a2.some( function ( v2 ) { return predicate( v1, v2 ); } );
	} );
}

export
function union ( a1, a2, predicate )
{
	predicate = sanitize_predicate( predicate );

	const rv = [];

	a1.forEach( function ( v1 )
	{
		if ( !rv.some( function ( v2 ) { return predicate( v1, v2 ); } ) )
		{
			rv.push( v1 );
		}
	} );

	a2.forEach( function ( v1 )
	{
		if ( !rv.some( function ( v2 ) { return predicate( v1, v2 ); } ) )
		{
			rv.push( v1 );
		}
	} );

	return rv;
}
