<script>

import { toString } from 'nn-type-cast';
import { isEmpty } from 'nn-validate';
import { watch } from 'vue';

import HttpRequestMixin from '@/mixins/http-request';
import NnDirectory from '@/components/nn-link-list';
import NnFace from '@/components/nn-face';
import NnPraetorian from '@/components/nn-praetorian';
import NnTextFilter from '@/tools/nn-text-filter';

import { intersection } from '@/tools/array-tools';
import { jsonSilentParse } from '@/tools/json-tools';

import N13Configuration from '@/assets/configuration.json';

export default
{
	mixins: [ HttpRequestMixin ],
	components:
	{
		NnDirectory,
		NnFace,
		NnPraetorian
	},
	data ()
	{
		return	{
			dev: false,
			devVisible: false,
			devContent: '',
			working: true,
			content_src: '',
			default_image: '',
			search_value: '',
			content: undefined,
			raw: undefined
					};
	},
	methods:
	{
		setupContent ( info )
		{
			let tx_filter;
			if( this.search_value )
			{
				tx_filter = new NnTextFilter();
				tx_filter.strainer = this.search_value;
			}

			this.content = build_content.call( this, info.categories, info.links, tx_filter );
			this.default_image = cleanImagePath( toString( info.default_image ) );
		},
		saveDevContent ()
		{
			try
			{
				const [ ex, jsonDevContent ] = jsonSilentParse( this.devContent );

				if ( ex ) return;

				this.setupContent( jsonDevContent );
				this.devVisible = false;
			} catch ( ex ) {
				// eslint-disable-next-line no-console
				console.error( ex );
			}
		},
		showDev ()
		{
			this.devVisible = !this.devVisible;
		}
	},
	mounted ()
	{
		const query = new URLSearchParams( location.search );

		if ( query.has( 'dev' ) )
		{
			this.dev = true;
		}

		watch
		(
			() => ( `${this.$route.fullPath}/${this.search_value}` ),
			async () =>
			{
				let content = this.raw;

				const src = get_page_file.call( this, this.$route, N13Configuration[ 'content-source' ] );

				if ( src !== this.content_src || !content )
				{
					this.raw = content = await load.call( this, (this.content_src = src) );
				}

				if ( !isEmpty( content ) )
				{
					this.setupContent( content );
				} else {
					this.content = undefined;
				}

				this.working = false;
			},
			{
				immediate: true
			}
		);
	}
}

function cleanImagePath ( src )
{
	const re = new RegExp( N13Configuration.image.filter );
	return src && re.test( src ) ? src : N13Configuration.image[ 'default-src' ];
}

function get_page_file ( route, { default_ext, default_page, ext, page_root } )
{
	let page_file;

	if ( route.params.contentroute && route.params.contentroute !== '/' )
	{
		page_file = String( route.params.contentroute );
	} else {
		page_file = default_page;
	}

	return `${page_root}/${resolve_file_extension
	(
		page_file,
		default_ext,
		ext
	) }`;
}

async function load ( source )
{
	let rv;

	try
	{
		const res = await this.hr_request
		( {
			url: source,
			method: 'get',
			timeout: 8000
		} );

		if ( res && res.data ) rv = res.data;
	} catch ( ex ) {
		// eslint-disable-next-line no-console
		console.error( ex );

		rv = [];
	}

	return rv;
}

function build_content ( categories, links, filter )
{
	const rv = [];

	categories.forEach( ( cate ) =>
	{
		const category_rec =
		{
			token: String( cate.token ),
			name: toString( cate.name, '' ),
			default_image: cleanImagePath( toString( cate.default_image, '' ) ),
			links: []
		};

		links.forEach( ( link ) =>
		{
			const filter_text = category_rec.name.toUpperCase() + ' ' + link.name.toUpperCase();
			if( (!filter || filter.validate( filter_text ) ) && check_keywords( cate.keywords, link.keywords ) )
			{
				category_rec.links.push
				( {
					...link,
					image: cleanImagePath( toString( link.image ) )
				} );
			}
		} );

		if( category_rec.links.length > 0 )
		{
			rv.push( category_rec );
		}
	} );

	return rv;
}

function check_keywords( kw1, kw2 )
{
	return intersection( kw1, kw2 ).length > 0;
}

function prefix_ext ( ext )
{
	if ( !ext.startsWith( '.' ) ) ext = `.${ext}`;

	return ext;
}

function resolve_file_extension ( file, desired_ext, exts )
{
	desired_ext = prefix_ext( String( desired_ext ) );

	if( !Array.isArray( exts ) )
	{
		exts = [ exts != null ? String( exts ) : desired_ext ];
	}

	const ext_included = exts.some( function( ext )
	{
		return file.endsWith( prefix_ext( String( ext ) ) );
	} );

	return ext_included ? file : (file + desired_ext);
}

</script>

<template>
	<section class="page-landing">
		<template v-if="content">
			<div class="p-search">
				<input v-model="search_value" type="text" placeholder="Search" />
			</div>
			<nn-directory
				class="nn-directory"
				:default-image="default_image"
				:content="content"
			/>
		</template>
		<div v-else class="no-content">
			<nn-face />
			<p v-if="working">Loading...</p>
			<p v-else>No content</p>
		</div>
	</section>
	<section v-if="devVisible" class="page-dev">
		<div>
			<textarea v-model="devContent" />
		</div>
		<div class="button-container">
			<button type="button" @click="saveDevContent">Save</button>
		</div>
	</section>
	<nn-praetorian v-if="dev" @click="showDev" />
</template>

<style lang="scss" scoped>

.page-landing
{
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: stretch;

	.p-search
	{
		width: 100%;
		margin-bottom: 24px;

		input
		{
			width: 100%;
			padding: 4px 6px;
			border: 0 none;
			border-radius: 4px;
			background-color: var( --category-body-background-color );
			color: var( --category-body-text-color );

			&:focus
			{
				outline: 0 none;
				box-shadow: 0 0 8px 4px var( --category-body-shadow-color );
			}

			&::placeholder
			{
				color: rgba( var( --category-body-text-color ), 0.75 );
				text-align: center;
			}
		}
	}

	.nn-directory
	{
		width: 100%;
	}

	.no-content
	{
		max-width: 320px;
		text-align: center;
		text-transform: uppercase;

		h1
		{
			font-size: 4rem;
		}
	}
}

.page-dev
{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba( 0, 0, 0, 0.50 );

	textarea
	{
		resize: block;
		width: 100%;
		min-height: 320px;
		padding: 4px;
	}
}

</style>

<style lang="scss" theme="rose">

@import '~@/css/tm-rose/base';

html.tm-rose
{
	@include theme-vars();

	background-color: var( --page-background-color );
	background-image: var( --page-background-image );
}

</style>

<style lang="scss" theme="train">

@import '~@/css/tm-train/base';

html.tm-train
{
	@include theme-vars();

	background-color: var( --page-background-color );
	background-image: var( --page-background-image );
}

</style>
