
import axios from 'axios';
import { isObject } from 'nn-validate';

import { jsonSilentParse } from '@/tools/json-tools';

//==================================================================================================

export default
{
	data()
	{
		return	{
			http_request_ct: undefined
					};
	},
	methods:
	{
		async hr_kill_request ()
		{
			if( this.http_request_ct )
			{
				this.http_request_ct.cancel( 'Current request canceled.' );
				this.http_request_ct = undefined;
			}
		},
		async hr_request ( args, decoder = json_decode )
		{
			if ( args === null ) return Promise.resolve();

			this.hr_kill_request();

			const ct_source = this.http_request_ct = axios.CancelToken.source();

			try
			{
				const res = await axios.request
				( {
					...args,
					cancelToken: ct_source.token
				} )

				if ( res )
				{
					res.data = decoder( res.data );
				}

				return res;
			} catch( ex ) {
				if( process.env.NODE_ENV === 'development' )
				{
					// eslint-disable-next-line no-console
					console.error( ex );
				}

				throw ex;
			} finally {
				this.http_request_ct = undefined;
			}
		}
	},
	unmounted ()
	{
		this.hr_kill_request();
	}
}

function json_decode ( data )
{
	if ( isObject( data ) ) return data;

	const [ ex, content ] = jsonSilentParse( data );
	if( ex )
	{
		if( process.env.NODE_ENV === 'development' )
		{
			// eslint-disable-next-line no-console
			console.error( ex );
		}

		return;
	}

	return content;
}
