<script>

export default
{
	props:
	{
		defaultImage:
		{
			type: String,
			default: ''
		},
		content:
		{
			type: Array,
			reqired: true
		}
	}
};

</script>

<template>
	<div class="nn-directory" v-if="content.length > 0">
		<div class="d-category" v-for="(category, cindex) in content" :key="cindex">
			<div class="d-category-head">
				<span class="d-text">{{ category.name }}</span>
			</div>
			<div class="d-category-body">
				<div class="nn-grid">
					<template v-for="(link, lindex) in category.links" :key="lindex">
						<div class="nn-item">
							<a :href="link.link" :target="link.target||'_self'" :title="link.name">
								<img :src="link.image || category.default_image || defaultImage" />
								<span>{{ link.name }}</span>
							</a>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>

.nn-directory
{
	$d-head-background-color: var( --category-head-background-color, #FFFFFF );
	$d-head-text-color: var( --category-head-text-color, #000000 );
	$d-body-background-color: var( --category-body-background-color, #FFFFFF );
	$d-body-text-color: var( --category-body-text-color, #000000 );
	$d-body-shadow-color: var( --category-body-shadow-color, $d-body-text-color );

	.d-category
	{
		margin-top: 48px;
		color: $d-body-text-color;

		&:first-of-type
		{
			margin-top: 0;
		}
	}

	.d-category-head
	{
		font-size: 0.75em;
		text-align: center;
		text-transform: uppercase;
		color: $d-head-text-color;

		.d-text
		{
			display: inline-block;
			width: 100%;
			padding: 8px;
			background: $d-head-background-color;
			border-radius: 8px;
		}
	}

	.d-category-body
	{
		margin-top: 8px;
		background-color: $d-body-background-color;
		border-radius: 8px;
		color: $d-body-text-color;

		a
		{
			cursor: pointer;
			border-radius: 4px;
			outline: 0 none;
			color: inherit;
			text-decoration: none;
			text-decoration-line: none;
			text-shadow: 0 0 8px $d-body-shadow-color;

			&:focus,
			&:hover
			{
				background-color: rgba( 0, 0, 0, 0.20 );
			}

			&:visited
			{
				text-decoration: underline;
			}
		}
	}
}

.nn-grid
{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: center;
}

.nn-item
{
	padding: 8px;

	a
	{
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		width: 140px;
		padding: 4px;
		text-align: center;
	}

	img
	{
		vertical-align: top;
		width: 64px;
		margin-bottom: 8px;
	}
}

</style>
