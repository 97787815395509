<script>

import NnFace from '@/components/nn-face';

export default
{
	components:
	{
		NnFace
	}
}

</script>

<template>
	<section class="page-about">
		<p>This page was intentionally left blank.</p>
		<nn-face />
	</section>
</template>
