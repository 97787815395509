
import { createRouter, createWebHashHistory } from 'vue-router';

import LandingPage from './views/landing';

const routes =
[
	{
		name: 'about',
		path: '/@about',
		component: () => import( /* webpackChunkName: "about" */ '@/views/about' )
	},
	{
		name: 'common',
		path: '/:contentroute(.*)',
		component: LandingPage
	}
]

const router = createRouter
( {
	history: createWebHashHistory(),
	routes
} );

export default router;
