
export default
class NnTextFilter
{
	constructor()
	{
		this._strainer_ = '';
	}

	get strainer()
	{
		return this._strainer_;
	}

	set strainer( text )
	{
		this._strainer_ = sanitizeText( text );
	}

	validate ( text )
	{
		text = sanitizeText( text );

		let rv = 0;

		let ii = 0;
		let ji = 0;
		const ic = text.length;
		const jc = this._strainer_.length;
		let v1, v2;
		while( ii < ic && ji < jc )
		{
			v1 = text[ ii ];
			v2 = this._strainer_[ ji ];

			if( v1 !== '' && v2 !== '' && v1.indexOf( v2 ) >= 0 )
			{
				++rv;

				++ji;
			}

			++ii;
		}

		return rv >= jc;
	}
}

function sanitizeText( text )
{
	return String( text ).toUpperCase().replace( /[^A-Z0-9]+/gi, ' ' ).split( ' ' );
}
