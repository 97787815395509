<script>

const EMOTIONS = [ '', 'happy', 'sad', 'angry' ];

const line_width = 16;
const eye_half_width = 128;
const eye_half_height = 160;

export default
{
	name: 'nn-face',
	props:
	{
		emotion:
		{
			type: String,
			default: '',
			validator ( value ) { return EMOTIONS.includes( value ); }
		}
	},
	data ()
	{
		const size = 1024;

		const data =
		{
			canvas_size: size,
			canvas_half_size: size * 0.50,
			cursor_x: 0,
			cursor_y: 0,
			pupil1x: 0,
			pupil1y: 0,
			pupil2x: 0,
			pupil2y: 0,
			pressed: false
		};

		return data;
	},
	computed:
	{
		cheek_opacity ()
		{
			return this.comp_emotion === 'angry' ? 1 : 0;
		},
		direction ()
		{
			if ( this.cursor_x < 0 ) return 'looking-left';
			return 'looking-right';
		},
		comp_emotion ()
		{
			if ( this.emotion ) return this.emotion;

			if ( this.pressed ) return 'angry';

			if ( this.cursor_y <= 0 ) return 'happy';

			return 'sad';
		},
		face_size ()
		{
			return this.canvas_half_size - line_width;
		}
	},
	watch:
	{
		comp_emotion ()
		{
			this.$emit( 'update:emotion', this.comp_emotion );
		}
	},
	methods:
	{
		mousedown ()
		{
			this.pressed = true;
		},
		mouseup ()
		{
			this.pressed = false;
		},
		touch_start ()
		{
			this.pressed = true;
		},
		touch_stop ( ev )
		{
			this.pressed = false;
		}
	},
	mounted ()
	{
		if ( !this.receive_movement )
		{
			this.receive_movement = receive_movement.bind( this );
		}

		document.addEventListener( 'mousemove', this.receive_movement );
	},
	unmounted ()
	{
		document.removeEventListener( 'mousemove', this.receive_movement );
		this.receive_movement = undefined;
	}
}

function receive_movement ( ev )
{
	const canvas = this.$el.querySelector( '.canvas' );
	const scale = 1 / (canvas.clientWidth / this.canvas_size);

	const coords = scale_coords( get_cursor_coords_from_event( ev, canvas ), scale );
	this.cursor_x = coords.x - this.canvas_half_size;
	this.cursor_y = coords.y - this.canvas_half_size;

	[ this.pupil1x, this.pupil1y ]
		= calculate_pupil_pos( scale_coords( get_cursor_coords_from_event( ev, this.$el.querySelector( '.eyeball.f-left' ) ), scale ) );
	[ this.pupil2x, this.pupil2y ]
		= calculate_pupil_pos( scale_coords( get_cursor_coords_from_event( ev, this.$el.querySelector( '.eyeball.f-right' ) ), scale ) );
}

function get_cursor_coords_from_event( ev, relative )
{
	ev = ev || window.event; // IE-ism

	let view_x = 0;
	let view_y = 0;
	if( ev.clientX != null )
	{
		view_x = ev.clientX;
		view_y = ev.clientY;
	}

	const coords = { x: 0, y: 0 };

	if ( relative )
	{
		const bounds = relative.getBoundingClientRect();
		coords.x = view_x - bounds.left;
		coords.y = view_y - bounds.top;
	} else {
		const doc = ( (ev.target && ev.target.ownerDocument) || document).documentElement;
		const body = doc.body;

		coords.x = view_x +
			( (doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
			( (doc && doc.clientLeft) || (body && body.clientLeft) || 0);

		coords.y = view_y +
			( (doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
			( (doc && doc.clientTop) || (body && body.clientTop) || 0);
	}

	return coords;
}

function scale_coords ( coords, scale )
{
	return { x: coords.x * scale, y: coords.y * scale };
}

function calculate_pupil_pos ( coords )
{
	const max_x = eye_half_width * 0.75;
	const max_y = eye_half_height * 0.75;

	const ax = Math.floor( coords.x ) - eye_half_width;
	const ay = Math.floor( coords.y ) - eye_half_height;

	let angle = Math.atan2( -ay, ax );
	angle = angle >= 0 ? angle : (Math.PI + Math.PI + angle);

	let dist = Math.max( -max_x, Math.min( ax, max_x ) );
	const cx = Math.cos( angle ) * Math.abs( dist );

	dist = Math.max( -max_y, Math.min( ay, max_y ) );
	const cy = -Math.sin( angle ) * Math.abs( dist );

	return [ cx, cy ];
}

</script>

<template>
	<div class="nn-face">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlns:ev="http://www.w3.org/2001/xml-events"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			class="canvas"
			:class="[ direction, comp_emotion ]"
			:viewBox="`${-canvas_half_size} ${-canvas_half_size} ${canvas_size} ${canvas_size}`"
			:width="`${canvas_size}px`"
			:height="`${canvas_size}px`"
		>
			<defs>
				<radialGradient id="blush">
					<stop offset="0%" stop-color="#EB9A8C" />
					<stop offset="100%" stop-color="rgba( 255, 198, 180, 0 )" />
				</radialGradient>
				<radialGradient id="face-color" cx="0.25" cy="0.25" r="1">
					<stop offset="0%" stop-color="#E5C6B4" />
					<stop offset="45%" stop-color="#E5C6B4" />
					<stop offset="100%" stop-color="#BA8C7C" />
				</radialGradient>
			</defs>
			<circle
				class="face"
				cx="0" cy="0" :r="face_size"
				fill="url('#face-color')"
				@mousedown="mousedown"
				@mouseup="mouseup"
				@touchstart="touch_start"
				@touchend="touch_stop"
			/>
			<circle class="cheek f-left" cx="-192" cy="144" :r="192" fill="url('#blush')" :style="{ 'opacity': cheek_opacity }" />
			<circle class="cheek f-right" cx="192" cy="144" :r="192" fill="url('#blush')" :style="{ 'opacity': cheek_opacity }" />
			<ellipse class="eyeball f-left" cx="-144" cy="-120" rx="128" ry="160" />
			<ellipse class="eye-pupil f-left" :cx="`${pupil1x - 144}px`" :cy="`${pupil1y - 120}px`" rx="16" ry="16" />
			<ellipse class="eyeball f-right" cx="144" cy="-120" rx="128" ry="160" />
			<ellipse class="eye-pupil f-right" :cx="`${pupil2x + 144}px`" :cy="`${pupil2y - 120}px`" rx="16" ry="16" />
			<path class="eyebrow f-left" />
			<path class="eyebrow f-right" />
			<path class="mouth" />
			<path class="nose" :class="{ 'left': cursor_x < 0, 'right': cursor_x >= 0 }" />
		</svg>
	</div>
</template>

<style lang="scss">

%line
{
	fill: transparent;
	stroke: #000000;
	stroke-linecap: round;
	stroke-width: 4;
}

%transition
{
	transition: all 350ms ease 0ms;
}

.nn-face
{
	display: inline-block;
	width: 100%;

	.cheek,
	.eyeball,
	.eyebrow,
	.eye-pupil,
	.mouth,
	.nose
	{
		pointer-events: none;
	}

	.canvas
	{
		width: 100%;
		height: auto;
		vertical-align: top;
	}

	.cheek
	{
		@extend %transition;
	}

	.eyeball
	{
		@extend %line;
		fill: #FFFFFF;
	}

	.eyebrow
	{
		@extend %transition;
		@extend %line;
	}

	.eyebrow.f-left
	{
		d: path( 'M -256 -304 Q -144 -352 -32 -304' );
	}

	.eyebrow.f-right
	{
		d: path( 'M 256 -304 Q 144 -352 32 -304' );
	}

	.face
	{
		stroke: #000000;
		stroke-width: 4;
	}

	.mouth
	{
		@extend %transition;
		@extend %line;
	}

	.nose
	{
		@extend %transition;
		@extend %line;

		&.left
		{
			d: path( 'M 0 -28 Q -64 124 0 172' );
		}

		&.right
		{
			d: path( 'M 0 -28 Q 64 124 0 172' );
		}
	}

	.canvas.angry
	{
		.eyebrow.f-left
		{
			d: path( 'M -256 -304 Q -144 -352 -32 -304' );
		}

		.eyebrow.f-right
		{
			d: path( 'M 256 -304 Q 144 -352 32 -304' );
		}

		.mouth
		{
			d: path( 'M -272 280 Q 0 180 272 280' );
		}
	}

	.canvas.happy
	{
		.eyebrow.f-left
		{
			d: path( 'M -256 -304 Q -160 -368 -32 -352' );
		}

		.eyebrow.f-right
		{
			d: path( 'M 256 -304 Q 160 -368 32 -352' );
		}

		.mouth
		{
			d: path( 'M -272 224 Q 0 378 272 224' );
		}
	}

	.canvas.sad
	{
		.eyebrow.f-left
		{
			d: path( 'M -256 -304 Q -144 -328 -32 -352' );
		}

		.eyebrow.f-right
		{
			d: path( 'M 256 -304 Q 144 -328 32 -352' );
		}

		.mouth
		{
			d: path( 'M -272 301 Q 0 148 272 301' );
		}
	}

	.canvas.looking-left
	{
		.nose
		{
			d: path( 'M 0 -28 Q -64 124 0 172' );
		}
	}

	.canvas.looking-right
	{
		.nose
		{
			d: path( 'M 0 -28 Q 64 124 0 172' );
		}
	}
}

</style>
